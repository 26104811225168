img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

/* Add the rest of your styles here */
.wp-block-audio figcaption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .wp-block-audio figcaption { color: hsla(0,0%,100%,.65); }
.wp-block-code > code { font-family: Menlo, Consolas, monaco, monospace; color: #1e1e1e; padding: .8em 1em; border: 1px solid #ddd; border-radius: 4px; }
.wp-block-embed figcaption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .wp-block-embed figcaption { color: hsla(0,0%,100%,.65); }
.blocks-gallery-caption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .blocks-gallery-caption { color: hsla(0,0%,100%,.65); }
.wp-block-image figcaption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .wp-block-image figcaption { color: hsla(0,0%,100%,.65); }
.wp-block-pullquote { border-top: 4px solid; border-bottom: 4px solid; margin-bottom: 1.75em; color: currentColor; }
.wp-block-pullquote__citation, .wp-block-pullquote cite, .wp-block-pullquote footer { color: currentColor; text-transform: uppercase; font-size: .8125em; font-style: normal; }
.wp-block-quote { border-left: .25em solid; margin: 0 0 1.75em; padding-left: 1em; }
.wp-block-quote cite, .wp-block-quote footer { color: currentColor; font-size: .8125em; position: relative; font-style: normal; }
.wp-block-quote.has-text-align-right { border-left: none; border-right: .25em solid; padding-left: 0; padding-right: 1em; }
.wp-block-quote.has-text-align-center { border: none; padding-left: 0; }
.wp-block-quote.is-large, .wp-block-quote.is-style-large, .wp-block-quote.is-style-plain { border: none; }
.wp-block-search .wp-block-search__label { font-weight: 700; }
.wp-block-group:where(.has-background) { padding: 1.25em 2.375em; }
.wp-block-separator { border: none; border-bottom: 2px solid; margin-left: auto; margin-right: auto; opacity: .4; }
.wp-block-separator:not(.is-style-wide):not(.is-style-dots) { width: 100px; }
.wp-block-separator.has-background:not(.is-style-dots) { border-bottom: none; height: 1px; }
.wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) { height: 2px; }
.wp-block-table thead { border-bottom: 3px solid; }
.wp-block-table tfoot { border-top: 3px solid; }
.wp-block-table td, .wp-block-table th { padding: .5em; border: 1px solid; word-break: normal; }
.wp-block-table figcaption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .wp-block-table figcaption { color: hsla(0,0%,100%,.65); }
.wp-block-video figcaption { color: #555; font-size: 13px; text-align: center; }
.is-dark-theme .wp-block-video figcaption { color: hsla(0,0%,100%,.65); }
.wp-block-template-part.has-background { padding: 1.25em 2.375em; margin-top: 0; margin-bottom: 0; }



/* Styles for when menu is expanded */
.menu-toggle[aria-expanded="true"] + #primary-menu {
    display: block;
}

/* Mobile view media query */
@media (max-width: 768px) {
   
	
	.primary-navigation.toggled-on .nav-menu {
		display: block;
	}	
}

